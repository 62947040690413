// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./yekanbakh.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* لینک فایلی که وظیفه بارگذاری فونت ها را برعهده دارد */
body {
	font-family: Yekan Bakh  !important;
	direction: rtl;
	background-color: #cdcdcd;
	margin: 0;
}
h1, h2, h3, h4, h5, h6,input, textarea, button {
	font-family: Yekan Bakh !important;
}
h1 {
	font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/styles.css"],"names":[],"mappings":"AAA4B,wDAAwD;AACpF;CACC,mCAAmC;CACnC,cAAc;CACd,yBAAyB;CACzB,SAAS;AACV;AACA;CACC,kCAAkC;AACnC;AACA;CACC,iBAAiB;AAClB","sourcesContent":["@import url(yekanbakh.css); /* لینک فایلی که وظیفه بارگذاری فونت ها را برعهده دارد */\nbody {\n\tfont-family: Yekan Bakh  !important;\n\tdirection: rtl;\n\tbackground-color: #cdcdcd;\n\tmargin: 0;\n}\nh1, h2, h3, h4, h5, h6,input, textarea, button {\n\tfont-family: Yekan Bakh !important;\n}\nh1 {\n\tfont-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
